<button [disabled]="props().disabled || props().loading" class="button-base font-weight-600" (click)="onButtonClicked($event)" [class]="props().theme ? props().theme: 'blue'" [ngStyle]="{'color' : props().color, 'padding': props().padding, 'font-size': props().fontSize, 'opacity': props().disabled ? '0.6': '1', 'border': props().border}">
    <div class="button-content" [ngStyle]="{'visibility' : props().loading ? 'hidden': 'visible'}">
        @if(props().leadingIcon){
            <i [class]="props().leadingIcon" [ngStyle]="{'font-size': props().iconSize}"></i>
        }
        {{props().buttonText}}
        @if (props().trailingIcon) {
            <i [class]="props().trailingIcon" [ngStyle]="{'font-size': props().iconSize}"></i>
        }
    </div>
    <mat-progress-spinner class="btn-spinner" [ngStyle]="{'visibility' : props().loading ? 'visible': 'hidden', '--mdc-circular-progress-active-indicator-color': props().color }" [mode]="'indeterminate'" [strokeWidth]="2" [diameter]="18"/>
</button>